<template>
  <!-- <TemplateBlock modClass> -->
  <div class="row recovery-wrap">
    <div class="card p-5">
      <div class="card__wrap">
        <h1 class="card-title mb-4 mr-3">Введите код</h1>
      </div>

      <form @submit.prevent="onSubmit" class="push-form">
        <div class="container mb-2">
          <div class="input-wrapper">
            <PincodeInput v-model="code" />
          </div>

          <!--          <InputBlock-->
          <!--            v-model="data[0]"-->
          <!--            type="text"-->
          <!--            newClass="col-3"-->
          <!--            inputClass="push-form__input"-->
          <!--            maxLengthing="1"-->
          <!--          />-->

          <!--          <InputBlock-->
          <!--            v-model="data[1]"-->
          <!--            type="text"-->
          <!--            newClass="col-3"-->
          <!--            inputClass="push-form__input"-->
          <!--            maxLengthing="1"-->
          <!--          />-->

          <!--          <InputBlock-->
          <!--            v-model="data[2]"-->
          <!--            type="text"-->
          <!--            newClass="col-3"-->
          <!--            inputClass="push-form__input"-->
          <!--            maxLengthing="1"-->
          <!--          />-->

          <!--          <InputBlock-->
          <!--            v-model="data[3]"-->
          <!--            type="text"-->
          <!--            newClass="col-3"-->
          <!--            inputClass="push-form__input"-->
          <!--            maxLengthing="1"-->
          <!--          />-->
        </div>

        <div class="recovery-btn-wrap">
          <p class="mb-4">
            Не пришел код?
            <a @click="onBackClick" class="recovery-repeat-link"
              >Повторить попытку</a
            >
          </p>

          <!-- <router-link
              to="/main"
              class="btn btn-second mb-3 recovery-next-link"
            > -->
          <button type="submit" class="btn btn-second mb-3 recovery-next-link">
            Далее
          </button>
          <!-- </router-link> -->
        </div>
      </form>
    </div>
  </div>
  <!-- </TemplateBlock> -->
</template>

<script>
// import InputBlock from "../../components/Form/FormElement/Input";
import RequestManager from "../../function/request/RequestManager";
import PincodeInput from "vue-pincode-input";

export default {
  name: "RecoveryAuth",
  components: { PincodeInput },
  props: {
    email: {
      type: String
    }
  },
  code: "",
  data() {
    return {
      code: "",
      maxlength: 1
    };
  },
  methods: {
    onBackClick() {
      this.$emit("goback");
    },
    onSubmit() {
      const data = {
        email: this.email,
        code: this.code
      };
      RequestManager()
        .resetPassword(data)
        .then(() => {
          this.$router.push({ name: "Auth" });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
input.vue-pincode-input {
  margin: 10px;
  padding: 15px;
  max-width: 60px;
}

.recovery-wrap {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.push-form__input {
  font-size: 20px;
  line-height: 18px;

  text-align: center;
  letter-spacing: -0.165px;

  color: #58585e;
}

.recovery-btn-wrap {
  text-align: center;

  p {
    margin-bottom: 30px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.165px;

    color: #bebec0;
  }
}

.recovery-repeat-link {
  text-align: center;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: -0.165px;
  text-decoration-line: underline;
  cursor: pointer;
  color: #51a2be;
}

.recovery-next-link {
  display: inline-block;
  width: 100%;
}
</style>
